import { Container } from '@material-ui/core'
import {
  CheckboxCard,
  TextArea,
  List,
  Button,
  NotificationBanner,
  LinkStandalone,
} from '@velocity/ui'
import { FlexBox } from '@velocity/ui/draft'
import { FormApi } from 'final-form'
import {
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as React from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useTranslation } from '../../../context/TranslationContext/TranslationContext'
import { useUxConfig } from '../../../context/UxConfigContext/UxConfigContext'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { useMessageFormat } from '../../../hooks/useMessageFormat'
import { useTracking } from '../../../hooks/useTracking'
import { MalfunctionCode, VehicleServiceCode } from '../../../types/api'
import { Service } from '../../../types/models'
import { getActivityValidators } from '../../../utils/activityValidation'
import { filterByTranslation } from '../../../utils/filterByTranslation'
import { handleInputBlur } from '../../../utils/handleInputBlur'
import { FormField } from '../../FormField/FormField'
import { MessageBox } from '../../MessageBox/MessageBox'
import { MultiDropDownSelect } from '../../MultiDropDownSelect/MultiDropDownSelect'
import { TextWithInfoIcon } from '../../TextWithInfoIcon/TextWithInfoIcon'
import { NO_TRANSLATE_CLASS_NAME } from '../shared/constants'
import { StepFormButtons } from '../shared/StepFormButtons/StepFormButtons'
import { StepFormProps } from '../shared/types'
import { useStyles } from './ActivityForm.styled'
import { ServiceList } from './components/ServiceList/ServiceList'

export type ActivityFormValues = {
  /**
   * Array value of the selected regular services, e.g. Maintenance, MOT, ...
   */
  selectedRegularServices: VehicleServiceCode[]
  /**
   * Array value of all the selected vehicle problems
   */
  selectedMalfunctions: MalfunctionCode[]
  /**
   * Array value of all the selected extra services, e.g. Cleaning, Pickup & return, ...
   */
  selectedExtraServices: VehicleServiceCode[]
  /**
   * String value for additional written considerations regarding the service
   */
  additionalRequests: string
}

export interface ActivityFormProps extends StepFormProps<ActivityFormValues> {
  /**
   * Array with potential regular services
   */
  regularServices: Service<VehicleServiceCode>[]
  /**
   * Array with potential vehicle problems
   */
  malfunctions: Service<MalfunctionCode>[]
  /**
   * Array with potential extra services
   */
  extraServices: Service<VehicleServiceCode>[]
}

/**
 * Site core activity error keys defined in forms.activity."Error Messages"
 */
export type ActivityErrorSitecoreKey =
  | 'regularMaintenanceOrRepairRequired'
  | 'repairSelectedAndMalfunctionRequired'

const fieldIds = {
  selectedMalfunctions: 'selectedMalfunctionsId',
  additionalRequests: 'additionalRequestsId',
}
const fieldNames = Object.keys(fieldIds)

export const ACTIVITY_FORM_DEFAULTS: ActivityFormValues = {
  selectedRegularServices: [],
  selectedMalfunctions: [],
  selectedExtraServices: [],
  additionalRequests: '',
}

export enum Countries {
  AUSTRIA = 'LPAT',
  IRELAND = 'LPIE',
  ROMANIA = 'LPRO',
  NORWAY = 'LPNO',
  FRANCE = 'LPFR',
}

const REPAIR_BUTTON = 'REPAIR'
export const NCT_LINK = 'https://www.ncts.ie/bookings/'

export const ActivityForm: React.FC<ActivityFormProps> = (props) => {
  const {
    desktopView,
    error,
    initialValues = ACTIVITY_FORM_DEFAULTS,
    malfunctions,
    regularServices = [],
    extraServices = [],
    className,
    onFieldError,
    onGoBack,
    onSubmit,
  } = props

  const {
    countryConfig: { countryCode, contactPhone },
  } = useUxConfig()
  const { trackEvent } = useTracking()

  const [isRepair, setIsRepair] = React.useState<boolean>(
    countryCode === Countries.IRELAND
      ? Boolean(initialValues.selectedMalfunctions.length)
      : false,
  )
  const [activityError, setActivityError] =
    useState<ActivityErrorSitecoreKey | null>(null)

  const translations = useTranslation()
  const formTranslations = translations?.forms?.activity
  const sharedTranslations = translations?.forms?.shared
  const stepSharedTranslations = translations?.steps?.shared
  const [validationMutator, setFormApi, validate] =
    useFormValidation<ActivityFormValues>(fieldNames)
  const formApiRef = useRef<FormApi<ActivityFormValues>>()

  const stepOtherData = useMemo(
    () => ({ ...stepSharedTranslations?.otherData }),
    [stepSharedTranslations?.otherData],
  )

  const formLabels = useMemo(
    () => ({
      ...formTranslations?.labels,
      ...sharedTranslations?.labels,
    }),
    [formTranslations?.labels, sharedTranslations?.labels],
  )

  const formErrors = useMemo(
    () => ({
      ...formTranslations?.errorMessages,
      ...sharedTranslations?.errorMessages,
    }),
    [formTranslations?.errorMessages, sharedTranslations?.errorMessages],
  )

  const repairTranslation = useMemo(() => {
    const repair = filterByTranslation(translations.services, [REPAIR_BUTTON])
    return repair[0]
  }, [translations.services])

  const f = useMessageFormat<string>()
  const classes = useStyles()

  const handleSubmit = useCallback(
    (values: ActivityFormValues) => {
      const {
        selectedRegularServices,
        selectedMalfunctions,
        selectedExtraServices,
        additionalRequests,
      } = values

      if (isRepair && !selectedMalfunctions.length) {
        setActivityError('repairSelectedAndMalfunctionRequired')
        return
      }

      if (!selectedRegularServices.length && !selectedMalfunctions.length) {
        setActivityError('regularMaintenanceOrRepairRequired')
        return
      }

      onSubmit?.({
        selectedRegularServices,
        selectedMalfunctions,
        selectedExtraServices,
        additionalRequests,
      })
    },
    [onSubmit, setActivityError, isRepair],
  )

  const toggleRepair = (
    formProps: FormRenderProps<ActivityFormValues, Partial<ActivityFormValues>>,
  ) => {
    const isSelected = !isRepair
    setIsRepair(isSelected)

    // clear malfunctions list if 'Repair' not selected
    if (!isSelected) {
      formProps.form.change('selectedMalfunctions', [])
    }
  }

  const handleBlur = handleInputBlur(onFieldError)

  const validators = useMemo(() => {
    return getActivityValidators(f, formErrors)
  }, [f, formErrors])

  const malfunctionItems = useMemo(
    () =>
      malfunctions
        .map(({ label, value, enabled }) => ({
          label,
          value,
          enabled,
        }))
        .sort((a, b) =>
          a.value === MalfunctionCode.Other
            ? 1
            : a.label.localeCompare(b.label),
        ),
    [malfunctions],
  )

  const onFormValidate = useCallback(
    ({ selectedRegularServices, selectedMalfunctions }) => {
      //  check two conditions:
      //  1. regular service selected && repair btn not selected
      //  2. repair btn selected && malfunctions selected
      if (
        ((selectedRegularServices?.length && !isRepair) ||
          (isRepair && selectedMalfunctions?.length)) &&
        activityError
      ) {
        setActivityError(null)
      }

      return undefined
    },
    [activityError, setActivityError, isRepair],
  )

  // GA event, that collect Activity Form errors
  useEffect(() => {
    if (activityError) {
      trackEvent({
        event: 'formEvent',
        formName: 'garage booking',
        formStep: 'activity-form',
        formContext: 'ftb',
        formErrorField: '',
        formErrorType: activityError,
      })
    }
  }, [activityError, trackEvent])

  const hasDisabledRegularServices = useMemo(
    () => regularServices.some((s) => s.enabled === false),
    [regularServices],
  )

  const hasDisabledExtraServices = useMemo(
    () => extraServices.some((s) => s.enabled === false),
    [extraServices],
  )

  const hasDisabledMalfunctions = useMemo(
    () => malfunctions.some((m) => m.enabled === false),
    [malfunctions],
  )

  useEffect(() => {
    setFormApi(formApiRef.current)
    // only for initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    validate?.()
  }, [validate, validators])

  return (
    <Container className={className}>
      <Form<ActivityFormValues>
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize={true}
        initialValues={initialValues}
        validate={onFormValidate}
        mutators={{
          ...validationMutator,
          setMalfunctions: ([selectedMalfunctions], state, { changeValue }) =>
            changeValue(
              state,
              'selectedMalfunctions',
              () => selectedMalfunctions,
            ),
          setRegularServices: (
            [selectedRegularServices],
            state,
            { changeValue },
          ) =>
            changeValue(
              state,
              'selectedRegularServices',
              () => selectedRegularServices,
            ),
          setExtraServices: ([selectedExtraServices], state, { changeValue }) =>
            changeValue(
              state,
              'selectedExtraServices',
              () => selectedExtraServices,
            ),
        }}
        render={(formProps) => {
          formApiRef.current = formProps.form
          return (
            <form
              onSubmit={formProps.handleSubmit}
              data-e2e-component="activity-form"
              noValidate={true}
              autoComplete="on"
            >
              {/* for LPIE only */}
              {countryCode === Countries.IRELAND && (
                <div
                  className={classes.tooltipBox}
                  data-e2e-component="active-hint-box"
                >
                  <List>
                    <List.Item>
                      {f(stepOtherData['selectAllOptions'])}
                    </List.Item>
                    <List.Item>
                      {f(stepOtherData['ifOptionDisabled'], {
                        debugId: 'steps.shared.otherData.ifOptionDisabled',
                        values: { phone: contactPhone },
                      })}
                    </List.Item>
                    <List.Item>
                      {f(stepOtherData['nctIsNotAvailable'])}
                      <LinkStandalone
                        data-e2e-component="nct-hint-link"
                        kind="primary"
                        onClick={() => window.open(NCT_LINK, '_blank')}
                      >
                        {NCT_LINK}
                      </LinkStandalone>
                    </List.Item>
                  </List>
                </div>
              )}

              {Boolean(regularServices.length) && (
                <FormField<ActivityFormValues['selectedRegularServices']>
                  name="selectedRegularServices"
                  label={
                    <TextWithInfoIcon
                      text={f(formLabels['maintenance'], {
                        debugId: 'forms.activity.maintenance',
                      })}
                      tooltip={
                        hasDisabledRegularServices
                          ? f(formLabels['maintenanceInfo'], {
                              debugId: 'forms.activity.maintenanceInfo',
                            })
                          : null
                      }
                    />
                  }
                  classes={{ formControl: classes.field }}
                  required={true}
                  group={true}
                >
                  {({ input }) => (
                    <>
                      <ServiceList
                        {...input}
                        testId="regular-services-list"
                        services={regularServices}
                        onChange={formProps.form.mutators.setRegularServices}
                      />
                      {countryCode === Countries.IRELAND && (
                        <FlexBox flexWrap="wrap">
                          <div className={classes.checkboxCardWrapper}>
                            <CheckboxCard
                              value={undefined}
                              data-e2e-component="service-checkbox-repair"
                              title={repairTranslation.label}
                              details={repairTranslation.description}
                              className={classes.checkboxCard}
                              checked={isRepair}
                              onChange={() => toggleRepair(formProps)}
                            />
                          </div>
                        </FlexBox>
                      )}
                    </>
                  )}
                </FormField>
              )}

              {/* for LPIE only, show malfunctions list only if Repair btn selected,
              for another countries malfunctions list stays as usual */}
              {(countryCode !== Countries.IRELAND || isRepair) && (
                <div>
                  {/* Warning banner shows for LPIE only */}
                  {isRepair && (
                    <MessageBox
                      type="warning"
                      className={classes.warningBanner}
                      title={f(formLabels['contactUsDirectly'], {
                        debugId: 'forms.activity.contactUsDirectly',
                      })}
                      message={
                        <>
                          <List>
                            <List.Item>
                              {f(formLabels['vehicleNotDriveable'], {
                                debugId: 'forms.activity.vehicleNotDriveable',
                              })}
                            </List.Item>
                            <List.Item>
                              {f(formLabels['warningLights'], {
                                debugId: 'forms.activity.warningLights',
                              })}
                            </List.Item>
                            <List.Item>
                              {f(formLabels['wantHelp'], {
                                debugId: 'forms.activity.wantHelp',
                              })}
                            </List.Item>
                          </List>
                          <NotificationBanner.Buttons>
                            <Button variant="primary-filled">
                              Call{' '}
                              <a
                                className={classes.call}
                                href={`tel:${contactPhone}`}
                              >
                                {contactPhone}
                              </a>
                            </Button>
                          </NotificationBanner.Buttons>
                        </>
                      }
                    />
                  )}
                  <FormField
                    name="selectedMalfunctions"
                    validator={validators.malfunctions}
                    label={
                      <TextWithInfoIcon
                        text={f(formLabels['repairs'], {
                          debugId: 'forms.activity.repairs',
                        })}
                        tooltip={
                          hasDisabledMalfunctions
                            ? f(formLabels['repairsInfo'], {
                                debugId: 'forms.activity.repairsInfo',
                              })
                            : null
                        }
                      />
                    }
                    htmlFor={fieldIds.selectedMalfunctions}
                    classes={{ formControl: classes.field }}
                    hint={f(formLabels['repairsHelp'], {
                      debugId: 'forms.activity.repairsHelp',
                    })}
                    required={false}
                  >
                    {({ input, meta }) => (
                      <MultiDropDownSelect
                        {...input}
                        items={malfunctionItems}
                        placeholder={f(formLabels['repairs'], {
                          debugId: 'forms.activity.repairs',
                        })}
                        noItemsMessage={f(
                          formLabels['noMalfunctionsAvailable'],
                          {
                            debugId: 'forms.activity.noMalfunctionsAvailable',
                          },
                        )}
                        onBlur={(e) =>
                          handleBlur(
                            e as FocusEvent<HTMLInputElement>,
                            input.onBlur,
                            meta.error?.type,
                          )
                        }
                        error={!!meta.error && meta.touched}
                        onChange={formProps.form.mutators.setMalfunctions}
                        className={NO_TRANSLATE_CLASS_NAME}
                        data-e2e-component="malfunctions-select"
                      />
                    )}
                  </FormField>
                </div>
              )}

              {Boolean(extraServices.length) && (
                <FormField<ActivityFormValues['selectedExtraServices']>
                  name="selectedExtraServices"
                  label={
                    <TextWithInfoIcon
                      text={f(formLabels['extraServices'], {
                        debugId: 'forms.activity.extraServices',
                      })}
                      tooltip={
                        hasDisabledExtraServices
                          ? f(formLabels['extraServicesInfo'], {
                              debugId: 'forms.activity.extraServicesInfo',
                            })
                          : null
                      }
                    />
                  }
                  classes={{ formControl: classes.field }}
                  required={countryCode === Countries.AUSTRIA}
                  group={true}
                >
                  {({ input }) => (
                    <>
                      <ServiceList
                        {...input}
                        testId="extra-services-list"
                        services={extraServices}
                        onChange={formProps.form.mutators.setExtraServices}
                      />

                      {countryCode === Countries.AUSTRIA && (
                        <MessageBox
                          type="information"
                          message={
                            <p className={classes.infoText}>
                              {f(formLabels['replacementInfo'], {
                                debugId: 'forms.activity.replacementInfo',
                              })}
                              <LinkStandalone
                                component="a"
                                data-e2e-component="contact-link"
                                className={classes.contactButton}
                                kind="primary"
                                onClick={() => {
                                  window.open(`tel:${contactPhone}`, '_blank')
                                }}
                              >
                                {contactPhone}
                              </LinkStandalone>
                            </p>
                          }
                        />
                      )}
                    </>
                  )}
                </FormField>
              )}

              <FormField
                name="additionalRequests"
                label={f(formLabels['additionalRequests'], {
                  debugId: 'forms.activity.additionalRequests',
                })}
                htmlFor={fieldIds.additionalRequests}
                classes={{ formControl: classes.field }}
                hint={f(formLabels['additionalRequestsHelp'], {
                  debugId: 'forms.activity.additionalRequestsHelp',
                })}
                required={false}
              >
                {({ input }) => (
                  <TextArea
                    {...input}
                    value={input.value as string}
                    inputProps={{
                      'data-e2e-component': 'additional-requests-textarea',
                    }}
                    className={NO_TRANSLATE_CLASS_NAME}
                  />
                )}
              </FormField>
              {activityError && (
                <MessageBox
                  className={classes.activityError}
                  type="error"
                  title={f(formErrors['activityErrorTitle'], {
                    debugId: 'forms.activity.activityErrorTitle',
                  })}
                  message={f(formErrors[activityError], {
                    debugId: `forms.activity.${activityError}`,
                  })}
                />
              )}
              {!desktopView && error}
              <StepFormButtons
                onPrevious={() => onGoBack?.(formProps.values)}
                previousDisabled={formProps.submitting}
                nextDisabled={formProps.submitting}
              />
            </form>
          )
        }}
      />
    </Container>
  )
}
