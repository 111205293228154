import { createContext, FC, useContext } from 'react'

import { ActivityStepContainer } from '../../components/steps/ActivityStep'
import { DriverStepContainer } from '../../components/steps/DriverStep'
import { GarageStepContainer } from '../../components/steps/GarageStep'
import { SummaryStepContainer } from '../../components/steps/SummaryStep'
import { VehicleStepContainer } from '../../components/steps/VehicleStep'
import { MaybeCountryConfiguration } from '../../types/api'
import { CountryCode, AppLocale } from '../../types/i18n'
import { FlowStepName } from '../../types/models'
import { BrandTheme } from '../../types/theme'
import { UxConfig } from '../../types/uxConfig'
import { extractCountryFromLocale } from '../../utils/i18n'

const UxConfigContext = createContext<UxConfig | undefined>(undefined)

// Need to maintain referential integrity to avoid unnecessary page view fires
export const STEPS = [
  {
    name: FlowStepName.Vehicle,
    component: VehicleStepContainer,
  },
  {
    name: FlowStepName.Activity,
    component: ActivityStepContainer,
  },
  {
    name: FlowStepName.Garage,
    component: GarageStepContainer,
  },
  {
    name: FlowStepName.Driver,
    component: DriverStepContainer,
  },
  {
    name: FlowStepName.Summary,
    component: SummaryStepContainer,
  },
]

type BaseConfig<TSupportedLocales extends readonly AppLocale[] = AppLocale[]> =
  Omit<
    UxConfig<TSupportedLocales>,
    'countryConfig' | 'locale' | 'changeLocale' | 'theme'
  >

// Referential stability for the `country` and the `steps`
export const BASE_CONFIG: Partial<Record<CountryCode, BaseConfig>> = {
  fr: {
    steps: STEPS,
    country: {
      code: 'fr',
    },
    ui: {
      buttonVariant: 'original',
    },
  } as BaseConfig<['fr-fr']>, // statically narrowing down the available list of locales
  no: {
    steps: STEPS,
    country: {
      code: 'no',
    },
    ui: {
      buttonVariant: 'original',
    },
  } as BaseConfig<['nb-no', 'en-no']>,
  ie: {
    steps: STEPS,
    country: {
      code: 'ie',
    },
    ui: {
      buttonVariant: 'original',
    },
  } as BaseConfig<['en-ie']>,
  ro: {
    steps: STEPS,
    country: {
      code: 'ro',
    },
    ui: {
      buttonVariant: 'original',
    },
  } as BaseConfig<['ro-ro', 'en-ro']>,
  at: {
    steps: STEPS,
    country: {
      code: 'at',
    },
    ui: {
      buttonVariant: 'original',
    },
  } as BaseConfig<['de-at']>,
}

interface UxConfigProviderProps {
  locale?: AppLocale
  countryConfig: MaybeCountryConfiguration
  /**
   * Checks if the locale is available for the current country and changes the locale in the whole application.
   * @param locale New locale
   */
  changeLocale: (locale: AppLocale) => void
  theme: BrandTheme
}

export const UxConfigProvider: FC<UxConfigProviderProps> = ({
  children,
  // NB!: This is not an app default locale, 'en-gb' is. Automatically initializing
  // the provider's locale should only be used in locale-agnostic contexts that
  // need the UxConfig context, i.e. Storybook, tests
  locale = 'fr-fr',
  countryConfig,
  changeLocale,
  theme,
}) => {
  const country = extractCountryFromLocale(locale)
  const baseConfig = BASE_CONFIG[country]

  const uxConfig: UxConfig | undefined =
    baseConfig && countryConfig
      ? {
          ...baseConfig,
          locale,
          countryConfig,
          changeLocale,
          theme,
        }
      : undefined

  return (
    <UxConfigContext.Provider value={uxConfig}>
      {children}
    </UxConfigContext.Provider>
  )
}

export const useUxConfig = (): UxConfig => {
  const config = useContext(UxConfigContext)
  if (!config) {
    throw new Error(
      'No Context value found for `UxConfig`. Did you forget to wrap your application in the `UxConfigProvider`?',
    )
  }

  return config
}

export const useUxConfigUnsafe = (): UxConfig | undefined =>
  useContext(UxConfigContext)
