import { shouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill'

import {
  defaultLocale,
  supportedDateFnsLocales,
} from '../i18n/supportedLocales'
import { AppLocale } from '../types/i18n'
import { readCountryConfigFromWindow } from './countryConfig'

export const addLocalePolyfills = async () => {
  if (shouldPolyfill()) {
    await Promise.all([
      import('@formatjs/intl-pluralrules/polyfill'),
      import('@formatjs/intl-locale/polyfill'),
      import('@formatjs/intl-getcanonicallocales/polyfill'),
    ])
  }

  if (Intl.PluralRules['polyfilled']) {
    const countryConfig = readCountryConfigFromWindow()
    const countryCode = countryConfig?.countryCode

    switch (countryCode) {
      case 'LPFR':
        await import('@formatjs/intl-pluralrules/locale-data/fr')
        break
      case 'LPNO':
        await import('@formatjs/intl-pluralrules/locale-data/no')
        break
      case 'LPRO':
        await import('@formatjs/intl-pluralrules/locale-data/ro')
        break
      case 'LPAT':
        await import('@formatjs/intl-pluralrules/locale-data/de')
        break
      default:
        await import('@formatjs/intl-pluralrules/locale-data/en')
        break
    }
  }
}

// Returns a dateFns locale if it exists and is supported. Fallbacks to 'enGb'.
export const appLocaleToDateFns = (locale: AppLocale): Locale => {
  return (
    supportedDateFnsLocales[locale] || supportedDateFnsLocales[defaultLocale]
  )
}
