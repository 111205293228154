import { enGB, fr, nb, ro, enIE, deAT } from 'date-fns/locale'

import { AppLocale, CountryCode, LeaseplanCode } from '../types/i18n'

export const defaultLocale: AppLocale = 'en-gb'
export const defaultCountry: CountryCode = 'gb'
export const defaultLPCode: LeaseplanCode = `LPGB`

// We include all locale languages apart native even though we don't have dateFns
// english locales for some of them. Better to be flexible for the case dateFns
// adds them or we write our own versions.
export const supportedDateFnsLocales: Record<AppLocale, Locale> = {
  'fr-fr': fr,
  'en-ie': enIE,
  'nb-no': nb,
  'en-no': nb,
  'ro-ro': ro,
  'en-ro': ro,
  'de-at': deAT,
  [defaultLocale]: enGB,
}

export const supportedLocales = Object.keys(
  supportedDateFnsLocales,
) as ReadonlyArray<keyof typeof supportedDateFnsLocales>

export function isSupportedLocale(locale: string | null): locale is AppLocale {
  return !!locale && !!supportedDateFnsLocales[locale as AppLocale]
}
