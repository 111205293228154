import { TLossyFlagCode } from '@velocity/icons/lossyFlag'
import { ActionButton, Button, Text } from '@velocity/ui'
import React, { useMemo } from 'react'

import { appConfig } from '../../config/env/appConfig'
import { useTranslation } from '../../context/TranslationContext/TranslationContext'
import { useUxConfigUnsafe } from '../../context/UxConfigContext/UxConfigContext'
import { useMessageFormat } from '../../hooks/useMessageFormat'
import { supportedLocales } from '../../i18n/supportedLocales'
import { CountryCode } from '../../types/i18n'
import { BrandTheme } from '../../types/theme'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { PageBanner } from '../PageBanner/PageBanner'
import { useAvailableCountriesViewStyles } from './AvailableCountriesView.styled'

export const defaultLeasePlanUrl = 'https://www.leaseplan.com/'

export const defaultAyvensUrl = 'https://www.ayvens.com/'

export enum CountriesFullNames {
  fr = 'France',
  ie = 'Ireland',
  no = 'Norway',
  ro = 'Romania',
  gb = 'Great Britain',
  at = 'Austria',
}

export const AvailableCountriesView: React.FC = () => {
  const classes = useAvailableCountriesViewStyles()
  const enabledCountries = appConfig.enabledCountries
  const translations = useTranslation()
  const f = useMessageFormat()
  const theme = useUxConfigUnsafe()?.theme

  const labelsTranslations = translations?.forms?.shared?.labels
  const labels = useMemo(
    () => ({ ...labelsTranslations }),
    [labelsTranslations],
  )

  const getCountryFullName = (countryCode: CountryCode) =>
    CountriesFullNames[countryCode]

  const goToLeasePlanOnClick = () => {
    if (theme === BrandTheme.Ayvens) {
      window.open(defaultAyvensUrl, '_self')
    } else {
      window.open(defaultLeasePlanUrl, '_self')
    }
  }

  const goToFtbCountry = (country: CountryCode): void => {
    const currentLocale = supportedLocales.find((locale) =>
      locale.includes(country),
    )

    window.open(`/${currentLocale}/vehicle`, '_self')
  }

  const countryNavControls = useMemo(() => {
    return enabledCountries?.map((country) => {
      const flag = country.toUpperCase() as TLossyFlagCode

      return (
        <ActionButton
          data-e2e-component={`enabled-country-${country}`}
          className={classes.countryButton}
          key={country}
          label={getCountryFullName(country)}
          type="button"
          flagCode={flag}
          aria-label="country-list"
          size="m"
          onClick={() => goToFtbCountry(country)}
        />
      )
    })
  }, [classes.countryButton, enabledCountries])

  return (
    <div className={classes.root}>
      <Header hideLocaleSelector={true} />
      <PageBanner />
      <div className={classes.content}>
        <Text className={classes.title}>
          {f(labels['availableCountries'], {
            debugId: 'forms.shared.availableCountries',
          })}
        </Text>
        {countryNavControls}
        <Text bold={true} component="p" className={classes.text}>
          {f(labels['cantFind'], {
            debugId: 'forms.shared.cantFind',
          })}
        </Text>

        <Button
          data-e2e-component="go-to-leaseplan"
          variant="primary-outlined"
          className={classes.button}
          onClick={goToLeasePlanOnClick}
        >
          {f(labels['goToLP'], {
            debugId: 'forms.shared.goToLP',
          })}
        </Button>
      </div>
      <Footer className={classes.footer} />
    </div>
  )
}
